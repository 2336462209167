<template>
  <div :class="$store.state.isLoading ? 'isLoading' : 'none'">
    <div class="loader"></div>
  </div>
  <NavView @toggle-sidebar="toggle" @logout="logout" />

  <!-- <Sidebar :showSidebar="showSidebar" /> -->
  <router-view :key="$route.fullPath" />
  <footer-view />
</template>
<script setup>
import axios from "axios";
// import Sidebar from "@/components/Sidebar.vue";
import { onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
// const cart = [];

const store = useStore();
onBeforeMount(() => {
  store.commit("setIsLoading", true);
  store.commit("initializeStore");
  const token = store.state.token;
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    axios.defaults.headers.common["Content-Type"] = "application/json";
  } else {
    axios.defaults.headers.common["Authorization"] = "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
  }
});
onMounted(() => {
  preLoader();
});
const toggle = () => {
  store.state.showSidebar = !store.state.showSidebar;
};
const logout = () => {
  // this.$store.commit("removeToken");
  localStorage.removeItem("token");
  this.$router.push("/");
};
const preLoader = () => {
  setTimeout(() => {
    store.commit("setIsLoading", false);
  }, 1000);
};
</script>

<style>
:root {
  --primary--color: #ff3366;
  --secondry--color: #ff912d;
  --font: #0b031f;
  --font--para: rgb(115, 71, 224);
  --gap: 10px 10px;
  --text-letter-spacing: 1px;
  --nav-height: 5rem;
  --btn--1: #7e7d7d;
  --bdr--: #eaeaec;
}

* {
  margin: 0;
  padding: 0;
  font-family: Assistant, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 13px;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  color: var(--font);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* @media (prefers-color-scheme: light) {
  body {
    background: #fff;
    color: #333;
  }
}
@media (prefers-color-scheme: dark) {
  body {
    background: #333;
    color: #fff;
  }
} */
::-webkit-scrollbar {
  display: hidden;
  width: 0px;
}
html {
  font-size: 62%;
  /* transition: all 1s linear; */
  scroll-behavior: smooth;
  overflow-x: hidden;
}
@import "./fonts/fontawesome.min.css";
html ::-webkit-scrollbar {
  display: none;
}
nav a.router-link-exact-active {
  color: var(--primary--color);
}

.img-fluid {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.grid-2-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.grid-3-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
}
.grid-4-col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.brdr-2 {
  border: 2px solid black;
  border-radius: 20%;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-clm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.isLoading {
  background-color: rgba(255, 255, 255, 0.352);
  z-index: 9999;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader {
  border: 16px ridge #f3f3f3;
  border-radius: 50%;
  border-top: 16px ridge var(--primary--color);
  background-image: url("./assets/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.none {
  display: none;
}
.h-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 2vh;
  margin: 1vh 0;
  height: auto;
  max-width: 100vw;
}
.h-sec strong {
  font-weight: 100;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: "Monserat";
}
.Symbol {
  height: 2rem;
  width: 2rem;
  margin: 0.5rem;
}
.btm-cl {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.h-sec-bar {
  width: 100px;
  height: 1.5px;
  background-color: #7e7d7d;
}
.selling {
  font-size: 1.5rem;
  color: rgba(128, 128, 128, 0.514);
  margin-right: 10px;
  margin-bottom: 5px;
}
.prdct-price {
  text-decoration: line-through;
  font-size: 1.5rem;

  color: var(--bdr--);
  margin-right: 10px;
  margin-bottom: 5px;
}
.discount {
  padding-left: 5px;
  font-size: 1.3rem;
  color: green;
  margin-right: 10px;
  margin-bottom: 5px;
}
.size-row {
  display: flex;
  flex-direction: row;

  margin-bottom: 5px;
}
.size {
  padding: 2px 10px;
  margin: 5px;
  border: 1px solid var(--bdr--);
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.size-data {
  font-size: 1.2rem;
}
.btn-tanttra-1 {
  padding: 10px 10px;
  border: 1px solid var(--primary--color);
  color: var(--primary--color);
  text-transform: uppercase;
  font-style: bold;
  font-size: 1.1rem;
  background-color: transparent;
}

.btn-tanttra-2 {
  padding: 10px 20px;
  border: 1px solid var(--secondry--color);
  color: var(--secondry--color);
  background-color: transparent;
  text-transform: uppercase;
  font-style: bold;
  font-size: 1.1rem;
}
.btn-tanttra-3 {
  padding: 10px 20px;
  color: var(--bdr--);
  background-color: var(--primary--color);
  border: 1px solid var(--primary--color);
  text-transform: uppercase;
  font-style: bold;
  font-size: 1.1rem;
  cursor: pointer;
}
.btn-tanttra-1:hover {
  background-color: #fff1ec;
  color: var(--primary--color);
}
.btn-tanttra-2:hover {
  background-color: var(--secondry--color);
  color: white;
}
.btn-tanttra-3:hover {
  background-color: transparent;
  border: 1px solid var(--bdr--);
  color: var(--primary--color);
}
.fa {
  color: var(--primary--color);
}
.bdr {
  border: 1px solid #eaeaec;
}
.bg-orange {
  background-color: var(--primary--color);
  color: white;
}
.disable {
  background-color: rgb(138, 138, 138);
  color: white;
  text-decoration: line-through;
}
.hide {
  display: none;
}
</style>
