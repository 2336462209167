import axios from "axios";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import FooterView from "./components/FooterView.vue";
import Nav from "@/components/NavBar.vue";
import VueHtml2Canvas from "vue-html2canvas";
import "./registerServiceWorker";
axios.defaults.baseURL = "http://127.0.0.1:8000/";

const app = createApp(App);
app.component("footer-view", FooterView);
app.component("NavView", Nav);
app.use(VueHtml2Canvas);
app.use(store);
app.use(router);
app.mount("#app");
