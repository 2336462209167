import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/signin",
    name: "Signin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignIn.vue"),
  },
  {
    path: "/activate",
    name: "Activate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Activate.vue"),
  },
  {
    path: "/resend",
    name: "ResendOtp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResendOtp.vue"),
  },
  {
    path: "/reset",
    name: "Resetpassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Resetpassword.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    redirect: { name: "home" },
  },
  {
    path: "/shop/:category",
    name: "shop",
    props: true,
    // meta: { requireLogin: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ShopView.vue"),
  },

  {
    path: "/product-view/:id",
    name: "productview",
    props: true,
    // meta: { requireLogin: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductView.vue"),
  },
  {
    path: "/category-view/:name",
    name: "CategoryView",
    props: true,
    // meta: { requireLogin: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/CategoryView.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    props: true,
    // meta: { requireLogin: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CartView.vue"),
  },
  {
    path: "/wishlist",
    name: "wishlist",
    props: true,
    // meta: { requireLogin: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WishlistView.vue"),
  },
  {
    path: "/my-account",
    name: "account",
    props: true,
    // meta: { requireLogin: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AccountView.vue"),
  },
  {
    path: "/studio",
    name: "studio",
    props: true,
    // meta: { requireLogin: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Blogview.vue"),
  },
  {
    path: "/studio/:id",
    name: "BlogExpansionView",
    props: true,
    // meta: { requireLogin: true },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/BlogExpansionView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    next({ name: "home" });
  } else next();
});

export default router;
