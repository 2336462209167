import axios from "axios";
import { createStore } from "vuex";
import jsondata from "../static/data.json";
const moduleUser = {
  state: () => ({
    User: [],
  }),
  mutations: {
    setUser: (state, data) => (state.User = data),
  },
  actions: {
    async fetchUser({ commit }) {
      const responce = await axios.get("/users/api/user");
      commit("setUser", responce.data);
    },
  },
  getters: {
    getUser: (state) => state.User,
  },
};

export default createStore({
  state: {
    cart: { items: [] },
    wish_list: { items: [] },
    isAuthenticated: false,
    token: "",
    isLoading: false,
    viewed_product: [[]],
    category_data: [],
    nav_category: [],
    showSidebar: false,
    data_data: [],
    extra_fees: [],
    coupons: [],
    query: "",
  },
  getters: {
    menu_category: (state) => state.category_data,
    test_data: (state) => state.data_data,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }
      state.data_data = jsondata.test_data;
      state.extra_fees = jsondata.test_data.extra_fees;
      state.coupons = jsondata.test_data.coupons;
      state.category_data = jsondata.test_data.category_data;
      state.nav_category = JSON.stringify(jsondata.test_data.category);
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.token = "";
      state.isAuthenticated = false;
    },
    setIsLoading(state, boolean) {
      state.isLoading = boolean;
    },
    addToCart(state, item) {
      let selected = state.cart.items.find((e) => e.id === item.id);
      selected ? selected.qty++ : state.cart.items.push(item);
    },
    addToWish(state, item) {
      let selected = state.wish_list.items.find((e) => e.id === item.id);
      selected ? "" : state.wish_list.items.push(item);
    },
    viewedProduct(state, id) {
      let viewed = state.viewed_product[0].find((e) => e === id);
      viewed
        ? ""
        : (state.viewed_product[0].push(id),
          localStorage.setItem("viewed_product", state.viewed_product));
      state.viewed_product.length > 10 ? state.viewed_product.slice(5, 10) : "";
    },
    removeFromCart(state, id) {
      const index = state.cart.items.indexOf(
        state.cart.items.find((e) => e.id === id)
      );
      state.cart.items.splice(index, 1);
    },
    removeFromWish(state, id) {
      const index = state.wish_list.items.indexOf(
        state.wish_list.items.find((e) => e.id === id)
      );
      state.wish_list.items.splice(index, 1);
    },
    setQuery(state, query) {
      state.query = query;
    },
  },
  actions: {},
  modules: {
    a: moduleUser,
  },
});
