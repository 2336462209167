<template>
  <CarouselTwo :carousel="carousel2" timing="10" />
  <Heading heading="SHOP BY CATEGORY" />
  <CategoryBox :category="category" />
  <Heading heading="MEDAL WORTHY BRANDS TO BAG" />
  <CarouselOne :carousel="carousel1" timing="16" />
  <Heading heading="GRAND GLOBAL BRANDS" />
  <CarouselOne :carousel="carousel1" timing="20" />
  <div v-if="viewed_products[0] !== null">
    <Heading heading="LAST VIEWED PRODUCTS" />
    <CarouselOne :carousel="viewed_products" timing="20" />
  </div>
</template>

<script>
// @ is an alias to /src
import CarouselTwo from "@/components/CarouselTwo.vue";
import CarouselOne from "@/components/CarouselOne.vue";
import Heading from "@/components/HeadinfView.vue";
import CategoryBox from "@/components/CategoryBox.vue";
export default {
  name: "HomeView",
  components: {
    CarouselTwo,
    Heading,
    CarouselOne,
    CategoryBox,
  },
  emits: [""],
  computed: {
    // carousel() {
    //   return this.carousel1;
    // },
  },
  data() {
    return {
      // showSidebar: false,
      viewed_products: [localStorage.getItem("viewed_product")],
      category: JSON.parse(this.$store.state.nav_category),
      carousel1: [
        [1, 2, 3, 4, 5, 6],
        [1, 2, 3, 4, 5, 6],
        [1, 2, 3, 4, 5, 6],
        [1, 2, 3, 4, 5, 6],
        [1, 2, 3, 4, 5, 6],
      ],
      carousel2: [
        // Carousel items data here...

        {
          title: "BRIDE'S TALE",
          caption:
            "Every wedding comes with a bundle of emotions, be it love, happiness or sentiment. Embrace the journey of love and commitment with our handpicked selection of bridel collection.",

          image: "crsl_1.jpg",
          url: {
            name: "shop",
            params: { category: "men" },
            query: {
              sub_cate: "top wear",
              sub_sub_cate: "shirts",
            },
          },
        },
        {
          title: "BRIDE'S TALE",
          caption:
            "Every wedding comes with a bundle of emotions, be it love, happiness or sentiment. Embrace the journey of love and commitment with our handpicked selection of bridel collection.",

          image: "crsl_2.jpg",
          url: {
            name: "shop",
            params: { category: "men" },
            query: {
              sub_cate: "top wear",
              sub_sub_cate: "shirts",
            },
          },
        },
        {
          title: "BRIDE'S TALE",
          caption:
            "Every wedding comes with a bundle of emotions, be it love, happiness or sentiment. Embrace the journey of love and commitment with our handpicked selection of bridel collection.",

          image: "crsl_3.jpg",
          url: {
            name: "shop",
            params: { category: "men" },
            query: {
              sub_cate: "top wear",
              sub_sub_cate: "shirts",
            },
          },
        },
        {
          title: "BRIDE'S TALE",
          caption:
            "Every wedding comes with a bundle of emotions, be it love, happiness or sentiment. Embrace the journey of love and commitment with our handpicked selection of bridel collection.",

          image: "crsl_4.jpg",
          url: {
            name: "shop",
            params: { category: "men" },
            query: {
              sub_cate: "top wear",
              sub_sub_cate: "shirts",
            },
          },
        },
        {
          title: "BRIDE'S TALE",
          caption:
            "Every wedding comes with a bundle of emotions, be it love, happiness or sentiment. Embrace the journey of love and commitment with our handpicked selection of bridel collection.",

          image: "crsl_5.jpg",
          url: {
            name: "shop",
            params: { category: "men" },
            query: {
              sub_cate: "top wear",
              sub_sub_cate: "shirts",
            },
          },
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped></style>
