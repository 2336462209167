<template>
  <header>
    <nav>
      <!-- //logo -->
      <div class="logo">
        <div class="switch" @click="$emit('toggle-sidebar')">
          <div class="switch-logo-1"></div>
          <div class="switch-logo-2"></div>
        </div>
        <div class="logo-img">
          <router-link class="router-link" :to="{ name: 'home' }">
            <img alt="logo" class="img-logo" src="../assets/logo.png" />
          </router-link>
        </div>
        <div class="cart">
          <ul class="menu-items">
            <li
              class="menu-item-link"
              v-for="categor in json_Category"
              :key="categor.id"
            >
              <router-link
                class="router-link"
                :to="{ name: 'CategoryView', params: { name: categor.name } }"
              >
                {{ categor.name }}
              </router-link>
              <div class="menu-sub-items">
                <div class="menusub-items-col">
                  <div
                    v-for="sub_cate in categor.sub_category"
                    :key="sub_cate.id"
                  >
                    <h1 class="menusub-items-col-heading">
                      <router-link
                        class="router-link"
                        :to="{
                          name: 'shop',
                          params: { category: categor.name },
                          query: { sub_cate: sub_cate.name },
                        }"
                      >
                        {{ sub_cate.name }}
                      </router-link>
                    </h1>
                    <ul>
                      <li>
                        <p
                          v-for="sub_sub_cate in sub_cate.sub_sub_category"
                          :key="sub_sub_cate.id"
                        >
                          <router-link
                            class="router-link"
                            :to="{
                              name: 'shop',
                              params: { category: categor.name },
                              query: {
                                sub_cate: sub_cate.name,
                                sub_sub_cate: sub_sub_cate.name,
                              },
                            }"
                          >
                            {{ sub_sub_cate.name }}
                          </router-link>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li class="menu-item-link">
              <router-link :to="{ name: 'studio' }">Studio</router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- //serch -->
      <div class="menu">
        <div class="search-box">
          <button class="btn-search" @click="submit">
            <i class="fa fa-search"></i>
            <p>search</p>
          </button>
          <input
            type="text"
            class="input-search"
            placeholder="Type to Search..."
            v-model="query"
            @input="set_query"
            @keyup.enter="submit"
          />
        </div>
      </div>
      <!-- //right-coloumn -->
      <div class="cart">
        <ul class="menu-items">
          <li class="menu-item-link">
            <i class="fa fa-user-circle-o dm" aria-hidden="true"></i>
            <span>Profile</span>

            <div class="menu-sub-items">
              <div class="menusub-items-col" v-if="!isAuthenticated">
                <h1 class="menusub-items-col-heading">
                  Welcome {{ tittle.username }}
                </h1>
                <ul>
                  <li>
                    <router-link :to="{ name: 'account' }"
                      >Accounts</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Signin' }"
                      >Your Order</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Signin' }"
                      >Change Address</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'wishlist' }"
                      >Your Wishlist</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'Signin' }">Log Out</router-link>
                  </li>
                </ul>
              </div>
              <div class="menusub-items-col" v-else>
                <h1 class="menusub-items-col-heading"></h1>
                <ul>
                  <li>
                    <router-link :to="{ name: 'Login' }">Login</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'Signin' }">Sign In</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="menu-item-link bag">
            <router-link :to="{ name: 'wishlist' }">
              <i class="fa fa-heart-o dm" aria-hidden="true"></i>
              <span :class="[bagwish ? 'badege' : 'hidden ']">{{
                bagwish
              }}</span>
              Wishlist
            </router-link>
          </li>
          <li class="router-link menu-item-link bag">
            <router-link :to="{ name: 'cart' }">
              <i class="fa fa-shopping-bag dm" aria-hidden="true"
                ><span :class="[bagBadge ? 'badege' : 'hidden ']">{{
                  bagBadge
                }}</span></i
              >
              Bag
            </router-link>
          </li>
        </ul>
      </div>

      <div class="empty"></div>
      <div class="burger" @click.prevent="burgerC">
        <div class="burger-1"></div>
        <div class="burger-2"></div>
        <div class="burger-3"></div>
      </div>
      <div :class="side ? 'mobile-menu' : 'hidden'">
        <div class="router-link" @click.prevent="burgerC">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
        <!-- //serch -->
        <div class="menu-1">
          <div class="cart-1">
            <ul class="menu-items">
              <li
                class="menu-item-link"
                @click.prevent="burgerC"
                v-for="categor in json_Category"
                :key="categor.id"
              >
                <router-link
                  class="router-link"
                  :to="{ name: 'CategoryView', params: { name: categor.name } }"
                >
                  {{ categor.name }}
                </router-link>
                <div class="menu-sub-items">
                  <div class="menusub-items-col">
                    <div
                      v-for="sub_cate in categor.sub_category"
                      :key="sub_cate.id"
                      @click.prevent="burgerC"
                    >
                      <h1 class="menusub-items-col-heading">
                        <router-link
                          class="router-link"
                          :to="{
                            name: 'shop',
                            params: { category: categor.name },
                            query: { sub_cate: sub_cate.name },
                          }"
                        >
                          {{ sub_cate.name }}
                        </router-link>
                      </h1>
                      <ul>
                        <li>
                          <p
                            v-for="sub_sub_cate in sub_cate.sub_sub_category"
                            :key="sub_sub_cate.id"
                          >
                            <router-link
                              class="router-link"
                              :to="{
                                name: 'shop',
                                params: { category: categor.name },
                                query: {
                                  sub_cate: sub_cate.name,
                                  sub_sub_cate: sub_sub_cate.name,
                                },
                              }"
                            >
                              {{ sub_sub_cate.name }}
                            </router-link>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li class="menu-item-link">
                <router-link :to="{ name: 'studio' }">Studio</router-link>
              </li>
              <li class="menu-item-link">
                <router-link :to="{ name: 'Login' }">Login</router-link>
              </li>
              <li class="menu-item-link">
                <router-link :to="{ name: 'Signin' }">Sign In</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="bottom-mobile-menu">
      <div class="cart-2">
        <ul class="menu-items">
          <li class="menu-item-link">
            <router-link :to="{ name: 'home' }">
              <i class="fa fa-home bm" aria-hidden="true"></i>
              <span>Home</span>
            </router-link>
          </li>
          <li class="menu-item-link">
            <router-link :to="{ name: 'account' }">
              <i class="fa fa-user-circle-o bm" aria-hidden="true"></i>
              <span class="">Profile</span>
            </router-link>
          </li>
          <li class="menu-item-link bag">
            <router-link :to="{ name: 'wishlist' }">
              <i class="fa fa-heart-o bm" aria-hidden="true"></i>
              <span :class="[bagwish ? 'badege' : 'hidden ']">{{
                bagwish
              }}</span>
              Wishlist
            </router-link>
          </li>
          <li class="router-link menu-item-link bag">
            <router-link :to="{ name: 'cart' }">
              <i class="fa fa-shopping-bag bm" aria-hidden="true"
                ><span :class="[bagBadge ? 'badege' : 'hidden ']">{{
                  bagBadge
                }}</span></i
              >
              Bag
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script setup>
import { mapGetters, useStore } from "vuex";
import { defineEmits, defineProps, computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
defineProps({
  showSidebar: Boolean,
});
const store = useStore();
const router = useRouter();
defineEmits(["toggle-sidebar", "logout"]);
const query = ref();
const side = ref(false);
const isAuthenticated = store.state.isAuthenticated;
let json_Category = JSON.parse(store.state.nav_category);

const tittle = computed(() => {
  return reactive({ ...mapGetters({ tittle: "getUser" }) });
});
let bagBadge = computed(() => {
  return store.state.cart.items.length;
});
let bagwish = computed(() => {
  return store.state.wish_list.items.length;
});
const set_query = () => {
  store.commit("setQuery", query);
};
// let category_list = computed(() => {
//   return store.state.category_data.category;
// });
// onMounted(() => {
//   nav_category = reactive({ ...mapGetters({ category: "menu_category" }) });
//   console.log(nav_category);
// });
// watch: {
//   // whenever question changes, this function will run
//   isAuthenticated(newIsAuthenticated) {
//     if (newIsAuthenticated) {
//       this.isAuthenticated = this.$store.state.isAuthenticated;
//     }
//   },
// },

const submit = () => {
  router.push({ name: "shop", params: { category: "men" } });
};
const burgerC = () => {
  side.value = !side.value;
};
</script>
<style scoped>
nav {
  width: 100%;
  max-width: 100vw;
  display: flex;
  height: var(--nav-height);
  align-items: center;
  justify-content: space-evenly;
  background-color: #ffffff;
  position: fixed;
  border: 1px solid #eaeaec;
  z-index: 2;
  position: relative;
  gap: 2rem;
}
.bottom-mobile-menu {
  display: none;
}
.mobile-menu {
  height: 100vh;
  margin-right: 0;
  top: 3rem;
  right: 0;
  position: fixed;
  display: none;
  grid-template-rows: repeat(4, 1fr);
  width: 20rem;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #c0c0c0;

  border: 1px solid var(--bdr--);
  z-index: 9999;
}
.hidden {
  display: none;
}
.menu-item-link {
  padding: 0 10px;
  height: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  /* border: #c0c0c0;
  border-right-style: solid;
  border-left-style: solid; */
}
.dm {
  font-size: 1.5rem;
  padding-right: 10px;
}
.menu-items {
  list-style: none;
  display: flex;

  justify-content: space-evenly;
  max-height: 3rem;
  cursor: pointer;
  position: relative;
}
.menu-sub-items {
  display: none;
  position: absolute;
  background-color: #ffffff;
  list-style: none;
  top: 2.5rem;
  -webkit-box-shadow: 10px 10px 37px -18px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 37px -18px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 37px -18px rgba(0, 0, 0, 0.75);
  /* padding: 20px;
  margin-top: 20px; */
  z-index: 9999;
  text-align: justify;
  min-width: 300px;
}
.menu-item-link:hover > .menu-sub-items {
  display: flex;
  flex-wrap: wrap;

  animation: slide_right 1s linear;
}
.menu-sub-items:hover {
  display: flex;
}
.menusub-items-col {
  padding: 20px;
}
li {
  list-style: none;
  color: #000000;
  padding-bottom: 7px;
  font-size: 1rem;
}
@keyframes slide_right {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.img-logo {
  max-width: 100%;
  cursor: pointer;
  object-fit: contain;
  max-height: 3rem;
}
.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
  max-height: 3rem;
}
.input-search {
  padding: 10px;
  width: 300px;
  max-height: 3rem;
  background: none;
  border: 2px solid var(--bdr--);
  border-radius: 0px;
  box-sizing: border-box;
  font-family: Comic Sans MS;
  font-size: 26px;
  color: #000000;
  outline: none;
  transition: 0.5s;
  align-items: center;
}
.input-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  width: auto;
  height: auto;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: 50%;
  bottom: 50%;
  color: #c0c0c0;
  gap: 10px;
  background-color: transparent;
  pointer-events: painted;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-search:focus ~ .input-search {
  width: 400px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 400px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.router-link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.router-link-active {
  text-decoration: none;
  color: #00000063;
  cursor: pointer;
}
.burger,
.empty {
  display: none;
  margin-left: auto;
  margin-right: 40px;
}
.burger-1,
.burger-3 {
  width: 50px;
  border-radius: 50%;
  height: 2px;
  margin-bottom: 5px;
  transform: rotate(0deg);
  background-color: #c0c0c0;
}
.burger-2 {
  width: 40px;
  border-radius: 20%;
  height: 2px;
  margin: 5px;
  transform: rotate(0deg);
  /* background-color: #fff; */
  border: 6px double #c0c0c0;
}

.logout-2 {
  box-sizing: border-box;
  position: relative;
  display: block;
  border-radius: 16px;
  border: 3px solid;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border-top: 0px solid transparent;
}
.logout-2::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 10px;
  width: 3px;
  background: currentColor;
  left: 6px;
  bottom: 6px;
}
.logo {
  display: flex;
  flex-direction: row;
  width: auto;
}
.switch {
  width: 3rem;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  object-fit: contain;
  height: 3rem;
  border-right: 1px solid #c0c0c0;
}
.switch-logo-1 {
  padding: 0;
  margin: 0;
  width: 25px;
  height: 2px;
  background-color: #c0c0c0;
  /* transform: rotate(45deg); */
  transform: rotate(45deg);
}
.switch-logo-2 {
  padding: 0;
  margin-left: -5px;
  width: 25px;
  height: 2px;
  background-color: #c0c0c0;
  transform: rotate(-45deg);
}
.cart {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
}
.bag {
  position: relative;
}
.badege {
  background-color: var(--primary--color);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  right: -10px;
  bottom: -10px;
  text-align: center;
  color: white;
  position: absolute;
}
@media (max-width: 1254px) {
  .mobile-menu {
    display: grid;
  }
  .cart {
    display: none;
  }
  .burger,
  .empty {
    display: block;
    cursor: pointer;
  }
  .cart-1 > .menu-items {
    flex-direction: column;
    gap: 15px;
  }
  .menu {
    padding-left: 30px;
  }
}

@media (width<980px) {
  .bottom-mobile-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: var(--nav-height);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    border: 1mm ridge white;
    /* box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.05),
      25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
      inset -20px -20px 25px rgb(255, 255, 255, 0.9); */
    background-color: white;
  }
  .bm {
    font-size: 1.5rem;
    padding: 5px;
    /* color: white; */
  }
  .input-search {
    width: 200px;
  }
  .input-search:focus {
    width: 210px;
  }
  .btn-search:focus ~ .input-search {
    width: 210px;
  }
  .logo {
    display: none;
  }
}
</style>
