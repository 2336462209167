<template>
  <div class="carouseltwo">
    <div class="carouseltwo-inner">
      <div
        v-for="(item, index) in carouselItems.carousel"
        :key="index"
        :class="['carouseltwo-item', index === activeIndex ? 'active' : '']"
        :style="{ transitionDelay: 0.2 * index + 's' }"
      >
        <router-link :to="item.url">
          <img
            :src="resolve_image(item.image)"
            class="Img-fluid"
            alt="Slide Image"
          />
        </router-link>
        <!-- <div class="carouseltwo-caption">
          <h1>{{ item.title }}</h1>
          <p>{{ item.caption }}</p>
        </div> -->
        <div class="dot_col">
          <div
            v-for="i in carouselItems.carousel.length"
            :key="i"
            :class="['dot', i === activeIndex + 1 ? 'current_active' : '']"
            @click="activeIndex = i - 1"
          ></div>
        </div>
      </div>
    </div>
    <!-- 
    <a class="carousel-control-prev" role="button" @click="prevSlidetwo">
      <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
    </a>
    <a class="carousel-control-next" role="button" @click="nextSlidetwo">
      <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
    </a> -->
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps } from "vue";
import { resolve_image } from "../mixin/extrafunction";
const activeIndex = ref(0);
const autoplayInterval = ref(null);

const carouselItems = defineProps([`carousel`, `timing`]);

// const prevSlidetwo = () => {
//   activeIndex.value =
//     (activeIndex.value - 1 + carouselItems.length) % carouselItems.length;
// };

const nextSlidetwo = () => {
  activeIndex.value = (activeIndex.value + 1) % carouselItems.carousel.length;
};

onMounted(() => {
  // Start autoplay when component is mounted
  startAutoplay();
});

onUnmounted(() => {
  // Stop autoplay when component is unmounted
  stopAutoplay();
});

const startAutoplay = () => {
  autoplayInterval.value = setInterval(() => {
    nextSlidetwo();
  }, carouselItems.timing * 1000); // Change slide every 3 seconds (adjust as needed)
};

const stopAutoplay = () => {
  clearInterval(autoplayInterval.value);
};
</script>

<style scoped>
.carouseltwo {
  position: relative;
  margin: 20px;
  border: 4mm ridge var(--primary--color);
  /* box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.05),
    25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
    inset -20px -20px 25px rgb(255, 255, 255, 0.9); */
  overflow: hidden;
  max-width: 100vw;
}

.carouseltwo-inner {
  overflow: hidden;
}

.carouseltwo-item {
  display: none;

  transition: all 2s linear;
}
.Img-fluid {
  max-width: 100vw;

  height: auto;
  animation: slidein 1s linear, 5s slideout 5s ease-out;
}
@keyframes slidein {
  to {
    transform: translateY(0%);
  }
  from {
    transform: translateY(-100%);
  }
}
@keyframes slideout {
  to {
    transform: translateX(100%);
  }
  from {
    transform: translateX(0%);
  }
}
.carouseltwo-item.active {
  display: block;
}

.carouseltwo-caption {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  padding: 5%;
  /* animation: added 1s linear; */
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  color: #000000;

  border: none;

  z-index: 10;
  cursor: pointer;
}

.carousel-control-prev {
  left: 1rem;
}

.carousel-control-next {
  right: 1rem;
}
.dot_col {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 30%;
  width: 10vw;
  z-index: 9;

  height: auto;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  background-color: rgba(128, 128, 128, 0.557);
  /* position: absolute; */
  bottom: 10%;
  left: 50%;
  z-index: 9;
  margin: 5px;
}
.dot.current_active {
  background-color: rgba(0, 0, 0, 0.516);
  transform: scale(1.1);
  transition: all 1s linear;
}
@media (width<1000px) {
  .dot_col {
    display: none;
  }
}
</style>
