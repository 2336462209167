<template>
  <div class="category-box-row">
    <div v-for="item in categoryItems.category" :key="item.id">
      <div class="categor-box" @click="category_view(item.name)">
        <img
          :src="resolve_image(item.img)"
          class="Img-fluid"
          alt="Slide Image"
        />
        <div class="category-para">
          <span class="cat-tittle">{{ item.name.toUpperCase() }}</span>
          <!-- <span class="cat-brand">{{ item.brand }}</span>
          <span class="cat-offer">{{ item.offer }}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import { resolve_image } from "../mixin/extrafunction";
let categoryItems = defineProps([`category`]);
const router = useRouter();

const category_view = (name) => {
  router.push({ name: "CategoryView", params: { name: name } });
};
</script>
<style Scoped>
.category-box-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 15vh;
}
.categor-box {
  display: flex;
  flex-direction: column;
  margin: 10px;
  cursor: pointer;
  border: 4mm ridge var(--primary--color);
  box-shadow: inset 20px 20px 20px rgb(0, 0, 0, 0.05),
    25px 35px 20px rgb(0, 0, 0, 0.05), 25px 30px 30px rgb(0, 0, 0, 0.05),
    inset -20px -20px 25px rgb(255, 255, 255, 0.9);
}
.categor-box > img {
  width: auto;
  height: auto;
  max-width: 370px;
  max-height: 300px;
  vertical-align: middle;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}
.cat-tittle {
  font-size: 1.5rem;
  font-weight: 100;
}
.cat-brand {
  font-size: 1.5rem;
  font-weight: 100;
}
.cat-offer {
  font-size: 1rem;
}
.carouseltwo-item.active {
  display: block;
}
.category-para {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
